var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Link, Stack } from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ConfirmDialog, ErrorMessageBox, FallbackComponent } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { CopyDataFrom } from '../common/copyDataFrom';
import { WizardContext } from '../context';
import { useCancelAndDeleteMultiskill, useQueueAPI, useQueueHasMultiskill, useQueueStatus, useQueuesHasMultiskill, useRefreshQueueData, } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { usePlanAPI } from '../selectors';
import { ExceptionForm } from './exceptionForm';
import { TimeIntervalSelect } from './intervalSelect';
import { CLOSED, useChangeIntervalAPI, useCreateWapAPI, useDeleteWapAPI, useTimeCopyAPI, useWATGroup, } from './selectors';
import { WorkArrivalTimeTable } from './timeTable';
var DEFAULT_DAYS = function (month) { return _.times(7, function (day) { return ({ month: month, day: day, openTime: null, closeTime: null }); }); };
var DEFAULT_ITEMS = { 'month-1': DEFAULT_DAYS(-1) };
var validateForm = function (length) {
    if (length === void 0) { length = 0; }
    var itemShape = {};
    _.times(length + 1).forEach(function (month) {
        itemShape["month".concat(month - 1)] = yup
            .array()
            .of(yup.object().shape({
            openTime: yup.string().required(),
            closeTime: yup.string().required(),
        }))
            .test('at-least-open', 'atLeastOpen', function (arr) { return !arr || arr.some(function (wat) { return wat.openTime !== CLOSED; }); });
    });
    return yup.object().shape({
        queueId: yup.number().required(),
        timeInterval: yup.number().required(),
        items: yup.object().shape(itemShape).required(),
    });
};
export var WorkArrivalTimeContent = function (_a) {
    var wats = _a.wats, props = __rest(_a, ["wats"]);
    var watGroup = useWATGroup();
    return (_jsx(_Fragment, { children: _.map(wats !== null && wats !== void 0 ? wats : watGroup, function (data, month) { return (_jsx(WorkArrivalTimeTable, __assign({ data: data }, props, { month: data[0].month }), month)); }) }));
};
var initialValues = {
    wap: {},
    source: 0,
    clear: false,
};
export var WorkArrivalTimeContainer = function () {
    var _a, _b, _c;
    var t = useTranslation('wizard').t;
    var _d = useState(initialValues), confirmValues = _d[0], setConfirmValues = _d[1];
    var isViewOnly = useIsViewOnlyPlan();
    var planData = usePlanAPI()[0];
    var queueData = useQueueAPI()[0];
    var status = useQueueStatus();
    var _e = useForm({
        resolver: yupResolver(validateForm((_a = planData.data) === null || _a === void 0 ? void 0 : _a.periodLength)),
    }), control = _e.control, handleSubmit = _e.handleSubmit, reset = _e.reset, watch = _e.watch, setValue = _e.setValue, getValues = _e.getValues, isValid = _e.formState.isValid;
    var _f = watch().items, items = _f === void 0 ? {} : _f;
    var _g = useCreateWapAPI(), createdData = _g[0], createWAP = _g[1], clearCreatedData = _g[2];
    var _h = useChangeIntervalAPI(), changeIntervalData = _h[0], clearIntervalData = _h[2];
    var _j = useDeleteWapAPI(), deletedData = _j[0], deleteWAP = _j[1], clearDeletedData = _j[2];
    var _k = useTimeCopyAPI(), copiedData = _k[0], copyWAT = _k[1], clearCopyData = _k[2];
    var refreshData = useRefreshQueueData();
    var watGroup = useWATGroup();
    var fetchCancelAndDeleteMultiskill = useCancelAndDeleteMultiskill();
    var hasMultiskill = useQueueHasMultiskill();
    var queuesHasMultiskill = useQueuesHasMultiskill();
    var _l = useContext(WizardContext), handleSave = _l.handleSave, isDirty = _l.methods.formState.isDirty;
    useEffect(function () {
        if (queueData.data) {
            reset({
                queueId: queueData.data.id,
                timeInterval: queueData.data.timeInterval,
                items: _.isEmpty(watGroup) ? DEFAULT_ITEMS : watGroup,
            });
        }
    }, [reset, queueData.data, watGroup]);
    useEffect(function () {
        if (createdData.data || changeIntervalData.data || deletedData.data || copiedData.data) {
            refreshData();
        }
        return function () {
            clearCreatedData();
            clearIntervalData();
            clearDeletedData();
            clearCopyData();
        };
    }, [
        changeIntervalData.data,
        clearCopyData,
        clearCreatedData,
        clearDeletedData,
        clearIntervalData,
        copiedData.data,
        createdData.data,
        deletedData.data,
        refreshData,
    ]);
    var onClearData = function () {
        var _a;
        if (isDirty) {
            return handleSave('submit', function () { var _a; return deleteWAP(undefined, { queueId: (_a = queueData.data) === null || _a === void 0 ? void 0 : _a.id, month: -1 }); })();
        }
        deleteWAP(undefined, { queueId: (_a = queueData.data) === null || _a === void 0 ? void 0 : _a.id, month: -1 });
    };
    var onCopy = function (source) {
        if (isDirty) {
            return handleSave('submit', function () { return copyWAT(undefined, { source: source }); })();
        }
        copyWAT(undefined, { source: source });
    };
    var onSaveWAP = function (values) {
        if (isDirty) {
            return handleSave('submit', function () { return createWAP(__assign(__assign({}, values), { items: _.flatMap(values.items) })); })();
        }
        createWAP(__assign(__assign({}, values), { items: _.flatMap(values.items) }));
    };
    var handleContinue = function () {
        fetchCancelAndDeleteMultiskill();
        if (confirmValues.clear) {
            onClearData();
        }
        else if (confirmValues.source) {
            onCopy(confirmValues.source);
        }
        else {
            onSaveWAP(confirmValues.wap);
        }
        setConfirmValues(initialValues);
    };
    var handleSaveWAP = handleSubmit(function (values) {
        var _a;
        if (hasMultiskill || (queuesHasMultiskill && values.timeInterval !== ((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.timeInterval))) {
            setConfirmValues(__assign(__assign({}, confirmValues), { wap: values }));
        }
        else {
            onSaveWAP(values);
        }
    });
    var handleClearData = function () {
        if (hasMultiskill && (status === null || status === void 0 ? void 0 : status.wat)) {
            setConfirmValues(__assign(__assign({}, confirmValues), { clear: true }));
        }
        else {
            onClearData();
        }
    };
    var handleCopy = function (source) {
        if (hasMultiskill) {
            setConfirmValues(__assign(__assign({}, confirmValues), { source: source }));
        }
        else {
            onCopy(source);
        }
    };
    var handleAdd = function (month) {
        if (isDirty) {
            return handleSave('submit', function () { return setValue("items.month".concat(month), DEFAULT_DAYS(month)); })();
        }
        setValue("items.month".concat(month), DEFAULT_DAYS(month));
    };
    return (_jsxs(Stack, __assign({ position: "relative", spacing: 4, component: "form", onSubmit: handleSaveWAP }, { children: [_jsx(ErrorMessageBox, { errors: [createdData.error, changeIntervalData.error, deletedData.error, copiedData.error] }), _jsxs(FallbackComponent, __assign({ requesting: !!createdData.requesting ||
                    !!changeIntervalData.requesting ||
                    !!deletedData.requesting ||
                    !!copiedData.requesting, overlay: true }, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 2, alignItems: "center" }, { children: [_jsx(TimeIntervalSelect, { control: control, watch: watch, setValue: setValue, getValues: getValues }), ((_b = planData.data) === null || _b === void 0 ? void 0 : _b.isBaseline) && (_jsx(CopyDataFrom, { disabled: isViewOnly, disableClear: true, statusType: QueueStatusType.WAT, onCopy: handleCopy }))] })), _.map(items, function (arr, month) { return (_jsx(WorkArrivalTimeTable, { month: arr[0].month, data: arr, control: control, watch: watch, setValue: setValue, getValues: getValues }, month)); }), ((_c = planData.data) === null || _c === void 0 ? void 0 : _c.isBaseline) && (_jsxs(Grid, __assign({ container: true, rowSpacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, lg: 8 }, { children: _jsx(ExceptionForm, { selectedMonths: Object.keys(items), onAdd: handleAdd }) })), _jsxs(Grid, __assign({ item: true, xs: 12, lg: 4, justifyContent: "flex-end", display: "flex", gap: 2 }, { children: [Object.keys(items).length < 2 && (_jsx(Link, __assign({ disabled: isViewOnly, component: "button", type: "button", onClick: handleClearData }, { children: t('copyData.clearData') }))), _jsx(LoadingButton, __assign({ variant: "outlined", type: "submit", disabled: !isValid || isViewOnly, loading: createdData.requesting }, { children: t('wapTime.createTable') }))] }))] })))] })), _jsx(ConfirmDialog, { open: !!Object.values(confirmValues.wap).length || confirmValues.clear || !!confirmValues.source, onClose: function () { return setConfirmValues(initialValues); }, message: t('message.confirm'), confirmButtonText: "continue", onConfirm: handleContinue })] })));
};
