var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, ConfirmDialog, FormErrorText, FormField, NumberInput } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { BasePanel } from '../common/basePanel';
import { BaseVolumeTable } from '../common/baseTable';
import { QueuePanel } from '../common/queuePanel';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { TIME_INTERVAL } from '../constants';
import { WizardContext } from '../context';
import { useCancelAndDeleteMultiskill, usePlanStatus, useQueueAPI, useQueueStatus, useQueuesHasMultiskill, } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { usePlanAPI, usePlanType } from '../selectors';
import { useChangeIntervalAPI } from '../workArrivalPattern/selectors';
export var WorkingDayContent = function () {
    var _a, _b, _c, _d;
    var t = useTranslation('wizard', { keyPrefix: 'workingDay' }).t;
    var status = useQueueStatus();
    var isPlanWAP = usePlanStatus(QueueStatusType.WAT);
    var queueData = useQueueAPI()[0];
    var planData = usePlanAPI()[0];
    var isViewOnly = useIsViewOnlyPlan();
    var fetchCancelAndDeleteMultiskill = useCancelAndDeleteMultiskill();
    var isDisableDailyOperating = !!((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.isDeferrable) || !!(status === null || status === void 0 ? void 0 : status.wat) || isViewOnly;
    var _e = useState(), pendingInterval = _e[0], setPendingInterval = _e[1];
    var _f = useChangeIntervalAPI(), changeInterval = _f[1];
    var _g = useContext(WizardContext), handleSave = _g.handleSave, _h = _g.methods, control = _h.control, watch = _h.watch, setValue = _h.setValue, getValues = _h.getValues, errors = _h.formState.errors;
    var hasMultiskill = useQueuesHasMultiskill();
    var isNonTotalPartTime = !watch('totalPartTime');
    useEffect(function () {
        if (isNonTotalPartTime) {
            setValue('daysPerWeek', 0, { shouldDirty: true });
            setValue('hoursPerDay', 0, { shouldDirty: true });
        }
        else {
            !getValues('daysPerWeek') && setValue('daysPerWeek', null, { shouldDirty: true });
            !getValues('hoursPerDay') && setValue('hoursPerDay', null, { shouldDirty: true });
        }
    }, [getValues, isNonTotalPartTime, setValue]);
    var handleChangeInterval = function (interval) {
        setPendingInterval(interval);
    };
    var handleContinue = function () {
        fetchCancelAndDeleteMultiskill();
        setPendingInterval(undefined);
        return handleSave('submit', function () { var _a; return changeInterval({ basePlanId: (_a = planData.data) === null || _a === void 0 ? void 0 : _a.basePlanId, timeInterval: pendingInterval }); })();
    };
    var handleClose = function () {
        var _a;
        setValue('timeInterval', (_a = queueData.data) === null || _a === void 0 ? void 0 : _a.timeInterval, { shouldDirty: true });
        setPendingInterval(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(BaseVolumeTable, { name: "workingDays", disabled: !!(status === null || status === void 0 ? void 0 : status.wat) || isViewOnly, calculateWorkingDate: true }), _jsxs(Box, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 4 }, { children: [_jsx(FormField, __assign({ required: true, name: "fteDay", label: t('fteDay'), control: control, unitLabel: "FTE", disableHelperText: ((_b = errors === null || errors === void 0 ? void 0 : errors.fteDay) === null || _b === void 0 ? void 0 : _b.message) !== 'required' }, { children: _jsx(NumberInput, { disabled: isViewOnly, max: 24, decimal: 2 }) })), _jsx(FormField, __assign({ required: true, name: "timeInterval", label: t('timeInterval'), control: control, unitLabel: "MIN" }, { children: _jsx(Autocomplete, { options: TIME_INTERVAL, "data-testid": "timeInterval", getOptionLabel: function (_a) {
                                        var id = _a.id;
                                        return "".concat(id);
                                    }, disabled: !!isPlanWAP || !((_c = planData.data) === null || _c === void 0 ? void 0 : _c.isBaseline) || isViewOnly, onChange: handleChangeInterval }) })), _jsx(FormField, __assign({ required: true, name: "dailyOperatingHours", label: t('dailyOperatingHours'), control: control, unitLabel: "HRS" }, { children: _jsx(NumberInput, { min: 1, max: 24, decimal: 2, disabled: isDisableDailyOperating, placeholder: isDisableDailyOperating ? '-' : '' }) }))] })), _jsx(FormErrorText, { message: (_d = errors === null || errors === void 0 ? void 0 : errors.fteDay) === null || _d === void 0 ? void 0 : _d.message, scrollIntoView: false, bigText: false })] }), _jsxs(Stack, __assign({ paddingTop: 3 }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", gutterBottom: true }, { children: t('partTime.title') })), _jsx(Typography, __assign({ variant: "body1", gutterBottom: true, paddingBottom: 2 }, { children: t('partTime.description') })), _jsxs(Stack, __assign({ direction: "row", spacing: 4 }, { children: [_jsx(FormField, __assign({ required: true, name: "totalPartTime", label: t('totalPartTime'), control: control }, { children: _jsx(NumberInput, { disabled: isViewOnly, max: 100, decimal: 1, InputProps: {
                                        endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                                    } }) })), _jsx(FormField, __assign({ required: true, name: "hoursPerDay", label: t('hoursPerDay'), control: control }, { children: _jsx(NumberInput, { min: isNonTotalPartTime ? 0.0 : 1.0, max: 24, decimal: 1, disabled: isNonTotalPartTime || isViewOnly }) })), _jsx(FormField, __assign({ required: true, name: "daysPerWeek", label: t('daysPerWeek'), control: control }, { children: _jsx(NumberInput, { min: isNonTotalPartTime ? 0 : 1, max: 7, disabled: isNonTotalPartTime || isViewOnly }) }))] }))] })), _jsx(ConfirmDialog, { open: !!pendingInterval && !!hasMultiskill, onClose: handleClose, message: t('confirm'), confirmButtonText: "continue", onConfirm: handleContinue })] }));
};
export var WorkingDayStep = function () {
    var t = useTranslation('wizard', { keyPrefix: 'workingDay' }).t;
    var planType = usePlanType();
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsx(StepPanel, { children: _jsx(BasePanel, __assign({ title: t('title', { planType: planType }), statusType: QueueStatusType.WorkingDays }, { children: _jsx(WorkingDayContent, {}) })) }) })));
};
