var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Link, Stack, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CURRENCY_CODE } from '../../enum';
import { usePlatformContext } from '../app/selectors';
import { ErrorMessageBox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../common';
import { QueueType } from '../multiskill/types';
import { useResourcingContext } from '../report/hooks';
import { DisplayMode } from '../report/resourcing';
import { useDisplayModeParams, useExportAPI, useIsViewOnlyPlan, usePrintTableAPI } from '../report/selectors';
import { PLAN_COLORS, WizardStep } from '../wizard/constants';
import { AGENT_ROWS, ANNUAL_REPORT_ROWS, BUDGET_ROWS, CALCULATE_DIFF, COST_ROWS, CURRENCY, HIDE_ROW, INCLUDE_ROLE, MULTISKILL_COLOR, OCCUPANCY_ROWS, PERCENTAGES, PRODUCTION_HOURS_ROWS, SERVICE_ROWS, SUPPORT_STAFF_ROWS, TWO_DECIMAL_VALUE, metricTargetMap, } from './constants';
import { generateVariances, useMultiskillScenariosAPI, useSelectCurrency, useSelectQueuesByCost, useSelectQueuesByName, useSelectRoles, useSelectScenario, useSummaryByDisplayMode, useSummaryChunkSize, useSummaryReportAPI, } from './selectors';
import { ConstraintType, DialogMode } from './types';
var STICKIES = ['23.75rem', '17rem'];
var FirstCell = styled(TableCell)(function (_a) {
    var theme = _a.theme, paddingLeft = _a["aria-label"];
    return ({
        textAlign: 'left',
        minWidth: theme.typography.pxToRem(380),
        '&.MuiTableCell-root': {
            paddingLeft: paddingLeft,
        },
    });
});
var HeaderCell = function (_a) {
    var _b = _a.title, title = _b === void 0 ? 'Baseline' : _b, handleOpenDialog = _a.handleOpenDialog, highlightBorder = _a.highlightBorder, isMultiskill = _a.isMultiskill, color = _a.color, scenarioNameHeader = _a.scenarioNameHeader, props = __rest(_a, ["title", "handleOpenDialog", "highlightBorder", "isMultiskill", "color", "scenarioNameHeader"]);
    var scenario = useSelectScenario(title);
    var isViewOnly = useIsViewOnlyPlan();
    var t = useTranslation('report').t;
    return (_jsx(TableCell, __assign({ color: color !== null && color !== void 0 ? color : PLAN_COLORS[props.headerColor][0], sx: { minWidth: '17rem' }, colSpan: 2, highlightBorder: highlightBorder }, { children: scenarioNameHeader ? (_jsxs(Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "center" }, { children: [_jsxs(Stack, __assign({ flex: 1, paddingLeft: isMultiskill ? 0 : '1.75rem' }, { children: [_jsx(Box, { children: title }), isMultiskill && _jsx(Box, { children: t('multiskill') }), !!props.year && _jsxs(Box, { children: ["Year ", props.year] })] })), !isMultiskill && (_jsx(IconButton, __assign({ size: "small", color: "inherit", disabled: isViewOnly, onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, DialogMode.RenameScenario, scenario) }, { children: _jsx(FontAwesomeIcon, { icon: faPenToSquare }) })))] }))) : (_jsx(_Fragment, { children: props.unit })) })));
};
var AnnualTableHead = function (_a) {
    var title = _a.title, handleOpenDialog = _a.handleOpenDialog, unit = _a.unit, expanded = _a.expanded, isExpandable = _a.isExpandable, onExpandToggle = _a.onExpandToggle, monoTone = _a.monoTone, scenarioNameHeader = _a.scenarioNameHeader;
    var data = useSummaryByDisplayMode();
    var chunkSize = useSummaryChunkSize();
    var t = useTranslation('report', { keyPrefix: 'period' }).t;
    var theme = useTheme();
    if (!data)
        return null;
    return (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(FirstCell, __assign({ stickySizes: STICKIES }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [title, isExpandable && (_jsx(Link, __assign({ component: "button", onClick: onExpandToggle, underline: "none", sx: { fontWeight: 'normal', color: 'white', ':hover': { color: 'white' } } }, { children: expanded ? "- ".concat(t('hideDetails')) : "+ ".concat(t('showDetails')) })))] })) })), data === null || data === void 0 ? void 0 : data.map(function (plan, indx, arr) { return (_jsx(HeaderCell, { title: plan.name, headerColor: plan.colorIndex, color: monoTone ? theme.palette.text.primary : undefined, handleOpenDialog: handleOpenDialog, unit: unit, year: chunkSize === arr.length ? 0 : plan.year + 1, highlightBorder: indx + 1 !== arr.length && !((indx + 1) % chunkSize), isMultiskill: plan.dataType === QueueType.Multiskill, scenarioNameHeader: scenarioNameHeader }, "".concat(plan.name, "_").concat(plan.year, "_").concat(plan.dataType))); })] }) }));
};
var ActionCell = function (props) {
    var handleOpenDialog = props.handleOpenDialog, title = props.title, headerColor = props.headerColor, highlightBorder = props.highlightBorder, isMultiskill = props.isMultiskill, hasMultiskill = props.hasMultiskill;
    var t = useTranslation('report').t;
    var navigate = useNavigate();
    var isViewOnly = useIsViewOnlyPlan();
    var scenario = useSelectScenario(title);
    var multiSkillScenarios = useMultiskillScenariosAPI();
    var handleEdit = function () {
        if (isMultiskill) {
            navigate("/multiskill/".concat(scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId, "/").concat(scenario === null || scenario === void 0 ? void 0 : scenario.id));
        }
        else if (multiSkillScenarios.find(function (ms) { return ms.id === (scenario === null || scenario === void 0 ? void 0 : scenario.id); })) {
            handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog(DialogMode.DeleteAllMultiskill, scenario);
        }
        else {
            navigate("/wizard/".concat(scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId, "/").concat(scenario === null || scenario === void 0 ? void 0 : scenario.id, "?step=").concat(WizardStep.Review));
        }
    };
    return (_jsx(TableCell, __assign({ color: isMultiskill ? MULTISKILL_COLOR : PLAN_COLORS[headerColor][1], colSpan: 2, highlightBorder: highlightBorder }, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "flex-start", spacing: 2, justifyContent: "space-between" }, { children: [_jsx(Link, __assign({ component: "button", disabled: isViewOnly, onClick: handleEdit }, { children: t('annual.edit') })), !isMultiskill && (_jsx(Link, __assign({ disabled: hasMultiskill, component: "button", onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, DialogMode.Multiskill, scenario) }, { children: t('multiskill') }))), !isMultiskill && !(scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline) && (_jsx(Link, __assign({ component: "button", disabled: isViewOnly, onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, DialogMode.SwitchScenario, scenario) }, { children: t('annual.switch') }))), isMultiskill || !(scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline) ? (_jsx(Link, __assign({ component: "button", disabled: isViewOnly, onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, isMultiskill ? DialogMode.MultiskillDelete : DialogMode.DeleteScenario, scenario) }, { children: t('annual.delete') }))) : (_jsx(Typography, __assign({ visibility: "hidden" }, { children: t('annual.edit') })))] })) })));
};
var ActionRow = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog;
    var data = useSummaryByDisplayMode();
    var chunkSize = useSummaryChunkSize();
    return (_jsxs(TableRow, { children: [_jsx(FirstCell, { stickySizes: STICKIES }), data === null || data === void 0 ? void 0 : data.map(function (plan, indx, arr) { return (_jsx(ActionCell, { title: plan.name, headerColor: plan.colorIndex, handleOpenDialog: handleOpenDialog, highlightBorder: indx + 1 !== arr.length && !((indx + 1) % chunkSize), isMultiskill: plan.dataType === QueueType.Multiskill, hasMultiskill: plan.hasMultiskill }, "".concat(plan.name, "_").concat(plan.year, "_").concat(plan.dataType))); })] }));
};
var CostRow = function (_a) {
    var currency = _a.currency;
    var queueGroup = useSelectQueuesByCost();
    var data = useSummaryByDisplayMode();
    var chunkSize = useSummaryChunkSize();
    return (_jsx(_Fragment, { children: _.map(queueGroup, function (queues, name) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: name, stickySizes: STICKIES }), queues.map(function (queue, index, arr) {
                    var _a;
                    return (_jsx(TableCell, { colSpan: 2, decimal: 2, color: (data === null || data === void 0 ? void 0 : data[index].dataType) === QueueType.Multiskill && queue.group
                            ? MULTISKILL_COLOR
                            : PLAN_COLORS[(_a = data === null || data === void 0 ? void 0 : data[index].colorIndex) !== null && _a !== void 0 ? _a : 0][1], value: queue.costPerWork, currency: currency, highlightBorder: index + 1 !== arr.length && !((index + 1) % chunkSize) }, index));
                })] }, name)); }) }));
};
var MainRow = function (_a) {
    var title = _a.title, _b = _a.color, color = _b === void 0 ? '#CCCCCC' : _b, keyItem = _a.keyItem, bold = _a.bold, _c = _a.isHideRow, isHideRow = _c === void 0 ? false : _c, monoTone = _a.monoTone, props = __rest(_a, ["title", "color", "keyItem", "bold", "isHideRow", "monoTone"]);
    var t = useTranslation('report', { keyPrefix: 'period' }).t;
    var data = useSummaryByDisplayMode();
    var currency = useSelectCurrency();
    var chunkSize = useSummaryChunkSize();
    var isPercentages = useResourcingContext().isPercentages;
    var displayMode = useDisplayModeParams()[0];
    if (!data)
        return null;
    return (_jsx(_Fragment, { children: keyItem === 'costPerWork' ? (_jsx(CostRow, { currency: CURRENCY_CODE[currency] })) : (_jsxs(TableRow, { children: [_jsx(FirstCell, __assign({ color: !keyItem ? color : undefined, stickySizes: STICKIES, bold: bold }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [title, isHideRow && (_jsx(Link, __assign({ component: "button", onClick: props.onShowRow, underline: "none", sx: { fontWeight: 'normal' } }, { children: props.showDetails ? "- ".concat(t('hideDetails')) : "+ ".concat(t('showDetails')) })))] })) })), _.times(data.length / chunkSize, function (year) {
                    return _.times(chunkSize, function (n) {
                        var _a;
                        var index = n + year * chunkSize;
                        var plan = data[index];
                        var basePlan = data[year * chunkSize];
                        return (_jsx(TableCell, { bold: bold, colSpan: 2, decimal: keyItem && TWO_DECIMAL_VALUE.includes(keyItem) ? 2 : 0, color: monoTone
                                ? color
                                : data[index].dataType === QueueType.Multiskill && keyItem && plan[keyItem] !== null
                                    ? MULTISKILL_COLOR
                                    : (_a = PLAN_COLORS[plan === null || plan === void 0 ? void 0 : plan.colorIndex]) === null || _a === void 0 ? void 0 : _a[1], value: keyItem ? plan[keyItem] : undefined, percentage: keyItem && PERCENTAGES.includes(keyItem), currency: keyItem && CURRENCY.includes(keyItem) ? CURRENCY_CODE[currency] : undefined, highlightBorder: index !== data.length - 1 && n === chunkSize - 1, expandchildren: displayMode !== DisplayMode.All && keyItem && CALCULATE_DIFF.includes(keyItem)
                                ? generateVariances(basePlan[keyItem], plan[keyItem], isPercentages)
                                : undefined, useColorValue: true }, "".concat(plan.name, "_").concat(plan.year, "_").concat(plan.dataType)));
                    });
                })] })) }));
};
var RoleTable = function (_a) {
    var keyItem = _a.keyItem;
    var roles = useSelectRoles(keyItem);
    var currency = useSelectCurrency();
    var isPercentages = useResourcingContext().isPercentages;
    var data = useSummaryByDisplayMode();
    var displayMode = useDisplayModeParams()[0];
    if (!data)
        return null;
    return (_jsx(_Fragment, { children: roles === null || roles === void 0 ? void 0 : roles.map(function (role) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: role.role, stickySizes: STICKIES }), role.value.map(function (values, year, arr) {
                    return values.map(function (value, index) {
                        var _a, _b;
                        return (_jsx(TableCell, { colSpan: 2, value: value, decimal: TWO_DECIMAL_VALUE.includes(keyItem) ? 2 : 0, color: data[index].dataType === QueueType.Multiskill
                                ? MULTISKILL_COLOR
                                : (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], currency: CURRENCY.includes(keyItem) ? CURRENCY_CODE[currency] : undefined, highlightBorder: year !== arr.length - 1 && index === values.length - 1, expandchildren: displayMode !== DisplayMode.All && CALCULATE_DIFF.includes(keyItem)
                                ? generateVariances(values[0], value, isPercentages)
                                : undefined }, index));
                    });
                })] }, role.role)); }) }));
};
var QueueRow = function (_a) {
    var queueName = _a.queueName, keys = _a.keys, _b = _a.hasSubItem, hasSubItem = _b === void 0 ? false : _b, constraintType = _a.constraintType;
    var queueData = useSelectQueuesByName(queueName, constraintType);
    var chunkSize = useSummaryChunkSize();
    var isPercentages = useResourcingContext().isPercentages;
    var data = useSummaryByDisplayMode();
    var displayMode = useDisplayModeParams()[0];
    if (!data || !queueData)
        return null;
    return (_jsx(_Fragment, { children: hasSubItem ? (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(FirstCell, { value: queueName, bold: true, stickySizes: STICKIES }), queueData === null || queueData === void 0 ? void 0 : queueData.flat().map(function (q, index, arr) {
                            var _a, _b;
                            return (_jsx(TableCell, { colSpan: 2, color: (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], highlightBorder: index + 1 !== arr.length && !((index + 1) % chunkSize) }, index));
                        })] }), keys.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: ANNUAL_REPORT_ROWS[key], "aria-label": "2rem", stickySizes: STICKIES }), queueData === null || queueData === void 0 ? void 0 : queueData.map(function (queues, year, arr) {
                            return queues.map(function (queue, index) {
                                var _a, _b, _c, _d;
                                return (_jsxs(React.Fragment, { children: [_jsx(TableCell, { bold: !queue.metricTarget, color: (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], sx: { minWidth: '6.4rem' }, value: queue[key], percentage: PERCENTAGES.includes(key), decimal: TWO_DECIMAL_VALUE.includes(key) ? 2 : 0, colSpan: key === 'serviceLevelForecast' ? 1 : 2, highlight: queue.metricTarget && queue.metricTarget === metricTargetMap[key], highlightBorder: key !== 'serviceLevelForecast' && year !== arr.length - 1 && index === queues.length - 1, useColorValue: true }), key === 'serviceLevelForecast' && (_jsx(TableCell, { sx: { minWidth: '6.4rem' }, value: queue.threshold, suffix: ' sec', color: (_d = PLAN_COLORS[(_c = data[index]) === null || _c === void 0 ? void 0 : _c.colorIndex]) === null || _d === void 0 ? void 0 : _d[1], highlightBorder: year !== arr.length - 1 && index === queues.length - 1, highlight: queue.metricTarget === metricTargetMap[key], useColorValue: true }))] }, "".concat(year, "_").concat(index)));
                            });
                        })] }, key)); })] })) : (keys.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: queueName, stickySizes: STICKIES, bold: constraintType === ConstraintType.Multiskill, sx: { backgroundColor: constraintType === ConstraintType.Multiskill ? MULTISKILL_COLOR : '' } }), queueData === null || queueData === void 0 ? void 0 : queueData.map(function (queues, year, arr) {
                    return queues.map(function (queue, index) { return (_jsx(TableCell, { value: queue[key], color: queue.group && data[index].dataType === QueueType.Multiskill
                            ? MULTISKILL_COLOR
                            : PLAN_COLORS[data[index].colorIndex][1], colSpan: 2, percentage: PERCENTAGES.includes(key), decimal: TWO_DECIMAL_VALUE.includes(key) ? 2 : 0, highlightBorder: year !== arr.length - 1 && index === queues.length - 1, expandchildren: displayMode !== DisplayMode.All && CALCULATE_DIFF.includes(key)
                            ? generateVariances(queues[0][key], queue[key], isPercentages)
                            : undefined, useColorValue: true }, "".concat(year, "_").concat(index))); });
                })] }, key)); })) }));
};
var ExpandRow = function (_a) {
    var data = _a.data, keyRow = _a.keyRow;
    var currency = useSelectCurrency();
    var chunkSize = useSummaryChunkSize();
    var columns = useMemo(function () {
        return data.reduce(function (acc, curr) {
            var agents = _.chain([curr === null || curr === void 0 ? void 0 : curr.defQueue, curr === null || curr === void 0 ? void 0 : curr.nonDefQueue, curr === null || curr === void 0 ? void 0 : curr.multiSkill])
                .flatten()
                .orderBy(['queueType', 'order'], ['desc', 'asc'])
                .map(function (_a) {
                var name = _a.name, value = _a.agentsSalaries, queueType = _a.queueType, group = _a.group;
                return ({
                    name: name,
                    value: value,
                    queueType: queueType,
                    group: group,
                });
            })
                .value();
            var staff = curr.staffSalaries
                .filter(function (_a) {
                var role = _a.role;
                return role !== 'Agent';
            })
                .map(function (_a) {
                var name = _a.role, value = _a.value;
                return ({ name: name, value: value, group: 1 });
            });
            acc.push(keyRow === 'agentsSalaries' ? agents : staff);
            return acc;
        }, []);
    }, [data, keyRow]);
    return (_jsx(_Fragment, { children: columns[0].map(function (_a, rowIdx) {
            var name = _a.name, queueType = _a.queueType;
            return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: name, "aria-label": "2rem", stickySizes: STICKIES, bold: queueType === QueueType.Multiskill, sx: { backgroundColor: queueType === QueueType.Multiskill ? MULTISKILL_COLOR : '' } }), columns.map(function (col, colIdx, arr) {
                        var _a;
                        return (_jsx(TableCell, { value: col[rowIdx].value, colSpan: 2, bold: col[rowIdx].queueType === QueueType.Multiskill, color: data[colIdx].dataType === QueueType.Multiskill && col[rowIdx].group
                                ? MULTISKILL_COLOR
                                : (_a = PLAN_COLORS[data[colIdx].colorIndex]) === null || _a === void 0 ? void 0 : _a[1], decimal: 0, currency: CURRENCY_CODE[currency], highlightBorder: colIdx + 1 !== arr.length && !((colIdx + 1) % chunkSize) }, "".concat(rowIdx, "-").concat(colIdx)));
                    })] }, name));
        }) }));
};
var DataTableGroup = function (_a) {
    var _b;
    var keys = _a.keys, constraintType = _a.constraintType, both = _a.both;
    var data = useSummaryByDisplayMode();
    var _c = useState(HIDE_ROW), showDetails = _c[0], setShowDetails = _c[1];
    var onShowRow = function (item) {
        setShowDetails(function (prev) { return (prev.includes(item) ? prev.filter(function (i) { return i !== item; }) : __spreadArray(__spreadArray([], prev, true), [item], false)); });
    };
    var queues = constraintType !== ConstraintType.Both ? (_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b[constraintType] : undefined;
    if (!keys || !data)
        return null;
    return (_jsxs(_Fragment, { children: [!!(queues === null || queues === void 0 ? void 0 : queues.length) && (_jsx(MainRow, { bold: true, monoTone: true, title: ANNUAL_REPORT_ROWS[constraintType] })), queues === null || queues === void 0 ? void 0 : queues.map(function (queue) { return (_jsx(QueueRow, { queueName: queue.name, keys: keys, hasSubItem: !both, constraintType: constraintType }, queue.name)); }), constraintType === ConstraintType.Both &&
                keys.map(function (key) {
                    return INCLUDE_ROLE.includes(key) ? (_jsx(RoleTable, { keyItem: key }, key)) : (_jsxs(React.Fragment, { children: [_jsx(MainRow, { title: ANNUAL_REPORT_ROWS[key], keyItem: key, bold: true, isHideRow: HIDE_ROW.includes(key), showDetails: showDetails.includes(key), onShowRow: function () { return onShowRow(key); } }), showDetails.includes(key) && _jsx(ExpandRow, { data: data, keyRow: key })] }, key));
                })] }));
};
var AnnualTableBody = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog, headerRows = _a.headerRows, _b = _a.both, both = _b === void 0 ? true : _b;
    return (_jsxs(TableBody, { children: [handleOpenDialog && _jsx(ActionRow, { handleOpenDialog: handleOpenDialog }), Object.keys(headerRows).map(function (key) { return (_jsx(React.Fragment, { children: _jsx(DataTableGroup, { keys: headerRows[key], constraintType: key, both: both }) }, key)); })] }));
};
var AnnualTable = function (props) {
    var summaryData = useSummaryReportAPI()[0];
    if (!summaryData.data)
        return null;
    return (_jsx(TableContainer, __assign({ topScroll: true }, { children: _jsx(Table, { children: props.children }) })));
};
export var ResourcingTable = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog;
    var t = useTranslation('report', { keyPrefix: 'annual' }).t;
    var summaryData = useSummaryReportAPI()[0];
    var printData = usePrintTableAPI()[0];
    var exportData = useExportAPI()[0];
    var _b = useState(false), occupancyExpanded = _b[0], setOccupancyExpanded = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [summaryData.error, printData.error, exportData.error] }), _jsxs(AnnualTable, { children: [_jsx(AnnualTableHead, { handleOpenDialog: handleOpenDialog, title: t('serviceOrProductivity'), scenarioNameHeader: true }), _jsx(AnnualTableBody, { handleOpenDialog: handleOpenDialog, headerRows: SERVICE_ROWS, both: false }), _jsx(AnnualTableHead, { title: t('occupancyOrProductivity'), isExpandable: true, expanded: occupancyExpanded, onExpandToggle: function () { return setOccupancyExpanded(!occupancyExpanded); }, monoTone: true }), occupancyExpanded && _jsx(AnnualTableBody, { headerRows: OCCUPANCY_ROWS }), _jsx(AnnualTableHead, { title: t('productionHours'), unit: t('hours') }), _jsx(AnnualTableBody, { headerRows: PRODUCTION_HOURS_ROWS }), _jsx(AnnualTableHead, { title: t('agentRequirement'), unit: "# ".concat(t('ofAgents')) }), _jsx(AnnualTableBody, { headerRows: AGENT_ROWS }), _jsx(AnnualTableHead, { title: t('supportStaff'), unit: "# ".concat(t('ofStaff')) }), _jsx(AnnualTableBody, { headerRows: SUPPORT_STAFF_ROWS })] })] }));
};
export var FinancialTable = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog;
    var t = useTranslation('report', { keyPrefix: 'annual' }).t;
    var summaryData = useSummaryReportAPI()[0];
    var printData = usePrintTableAPI()[0];
    var exportData = useExportAPI()[0];
    var context = usePlatformContext();
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [summaryData.error, printData.error, exportData.error] }), _jsxs(AnnualTable, { children: [_jsx(AnnualTableHead, { handleOpenDialog: handleOpenDialog, title: t('staffBudget'), scenarioNameHeader: true }), _jsx(AnnualTableBody, { headerRows: BUDGET_ROWS }), _jsx(AnnualTableHead, { title: t('costPerWorkType', { context: context }), unit: t('cost') }), _jsx(AnnualTableBody, { headerRows: COST_ROWS })] })] }));
};
